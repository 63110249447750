import bsale from '../Bsale'
import { cart, getCart } from './cart'
import { collection, addToCartCollection, paintCollectionsProductsLazyLoad, setProductsToContainer } from './collection'
import { dropdownHeight, stickyHeader, searchHidden, hoverHeader, headerSearch, dropdownMultiLevel } from './common/header'

import { navigationSupport } from './common/support'

import { loadVideo, loadMap, initSlider, offerAlert, inicioAlert, contactPhone, contactChat, setCustomFunction, touchEvents } from '../util'
import { checkout } from './checkout'
import { form } from './form'
import { home } from './home'
import { product } from './product'

// its necessary to execute the code with a load event listener, due to owlcarousel loads later :/
window.addEventListener('load', () => {
  console.log('hola dev...')
  const [path, slug] = location.pathname.substr(1).split('/')

  console.log(`%cBsale %cVersión ${process.env.VERSION} %cRevisa documentación en %chttps://github.com/Bsale-IO/template-docs/wiki`,
              'padding:3px;color:#fff;background-color:#ff6815',
               'padding:3px 8px;color:black;background-color:silver',
              'padding:3px',
              'padding:3px; color:cyan'
   );
  //estas se agregan a todas las paginas
  touchEvents();
  navigationSupport();
  loadVideo();
  loadMap();
  getCart();
  initSlider();
  offerAlert();
  contactPhone();
  contactChat();
  stickyHeader();
  hoverHeader();
  searchHidden();
  headerSearch();
  dropdownMultiLevel();
  setCustomFunction(setProductsToContainer);
  inicioAlert();
  /* new */dropdownHeight();
  /* new */ addToCartCollection();
  
  // se asignan segun url 
  switch (path) {
    case '':
      home()
      paintCollectionsProductsLazyLoad();
      break
    case 'product':
      product(slug)
      break
    case 'cart':
      if (bsale.cart.items) {
        cart(slug)
      }
      break
    case 'collection':
    case 'search':
    case 'brand':
    case 'products':
      collection(slug)
      break
    case 'checkout':
      checkout(slug)
      break
    case 'form':
      form()
      break
  }
  
  // Verifica si FontAwesome ya está cargado en el componente style css
  let isFontAwesomeLoaded = Array.from(document.querySelectorAll('link[rel="stylesheet"], link[rel="preload"]'))
  // Busca entre todos los enlaces de estilos o pre-cargados si alguno contiene "all.min.css" en su URL
      .some(link => link.href.includes('https://dojiw2m9tvv09.cloudfront.net/fontawesome-v2/css/all.min.css'));

  if (isFontAwesomeLoaded) {
  // Si FontAwesome ya está cargado, muestra un mensaje en la consola, con la versión custom
      console.log("%cFontawesome %c6.7.2 custom %chttps://fontawesome.com/",
          "padding:3px;color:#fff;background-color:#339AF0",
          "padding:3px 8px;color:black;background-color:silver",
          "padding:3px");
  } else {
    // lazy fontawesome load
    // Si FontAwesome no esta en el componente style css, carga la versión antigua
    const link = document.createElement('link')
    link.href = 'https://dojiw2m9tvv09.cloudfront.net/fontawesome/css/all.min.css'
    link.rel = 'stylesheet'
    document.head.appendChild(link)
    console.log(`%cFontawesome %c5.15.4 %chttps://fontawesome.com/`,
                'padding:3px;color:#fff;background-color:#339AF0',
                'padding:3px 8px;color:black;background-color:silver',
                'padding:3px',
                )
  }
})
