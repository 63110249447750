import noUiSlider from "nouislider"
import bsale from '../Bsale'
import { formatPrice } from '../util/formatPrice'
import { metrics } from '../Metrics'
import { initSlider, newCreateModal } from '../util'
import { updateCart, cart } from './cart'
import LazyLoad from "vanilla-lazyload"
import { Collection } from "../Bsale/Collection"
import { Product } from "../Bsale/Product"

export function collection() {
  if(bsale.collections[0] != undefined){

    var attrSelect = document.querySelectorAll('.bs-collection-filter-input, [data-bs="collection.filter.attr"]');
    var filters = []
    var form = document.getElementById('bs-collection-filter-form') || document.querySelector('[data-bs="collection.filter"]');
    var filterPrice = form.querySelector('#bs-collection-filter-price')
    var prevRange = []
    var auto = form.dataset.info === 'auto' ? true : false;
    var sort = document.querySelector('[data-bs="collection.sort"]');

    if (location.pathname === '/search') {
      const start = location.search.indexOf('search_text=') + 12
      const end = location.search.indexOf('&')
      const searchString = location.search.slice(start, end !== -1 ? end : location.search.length).replace(/\+/g, ' ')
      metrics.search(searchString)
    }

    if (document.body.contains(form)) {
      try {
        // if (window.innerWidth > 767) {
        //   form.querySelector('#bs-collection-filter-toggle').classList.add('show')
        // }
        if (location.search) {
          // var summary = bsale.createElement('span', null, ' - ')
          var search = decodeURI(location.search.slice(1)).split('&')
          /*
          * igualar los filtros del state a los filtros parseados de la url,
          * mostrar el filtro de precio si esta activo
          * y/o crear un elemento por cado filtro y mostrarlo como resumen
          */
          filters = search.map(function (rawFilter, i) {
            
            var filter = rawFilter.split('=')
            var filterBC = {}
            
            if (!/limit|order|way|search_text/.test(filter[0])) {
              
              if (filter[0].indexOf('Price') !== -1) {
                var price = parseFloat(filter[1])
                var current = filter[0] === 'minPrice'
                prevRange[current ? 0 : 1] = price
                filterPrice && filterPrice.classList.add('show')
              }
              else {

                var input = form.querySelector(
                  `input[value="${encodeURI(filter[1])}"][data-info="${filter[0]}"], input[value="${encodeURI(filter[1])}"][data-filter="${filter[0]}"]`
                )
                
                if (input) {
                  input.checked = true;
                  input.parentNode.classList.add('bs-active');
                }
              }//else
            }

            filterBC[filter[0]] = encodeURI(filter[1])
            return filterBC
          })

        }
      } catch (ex) {
        console.warn(ex);
      }

      // inicializacion del filtro de precio (libreria: nouislider)

      if (document.body.contains(filterPrice)) {
        try {
          var decimals = bsale.config.currency.decimals > 0 ? true : false;
          var range = document.querySelectorAll("[data-bs='filter.range']")[0];
          var min = parseFloat(range.dataset.min);
          var max = parseFloat(range.dataset.max);

          //crea elemento que muestra precios
          const prices = document.createElement('div');
          const priceMin = document.createElement('span');
          const priceMax = document.createElement('span');

          // sólo necesario para el css
          prices.setAttribute("data-bs", "filter.range.size");
          priceMin.setAttribute("data-bs", "filter.range.min");
          priceMax.setAttribute("data-bs", "filter.range.max");

          //dibuja la estructura en el html
          prices.insertBefore(priceMin, prices.children[0]);
          prices.insertBefore(priceMax, prices.children[1]);
          range.parentNode.insertBefore(prices, range.nextSibling);

          //"<div data-bs='filter.range.size'><span data-bs='filter.range.min'></span><span data-bs='filter.range.max'></span></div>";
          //dibuja valores 

          //range.parentNode.insertBefore(prices, range.nextSibling);
          //$("<div data-bs='filter.range.size'><span data-bs='filter.range.min'></span><span data-bs='filter.range.max'></span></div>").insertAfter(range)
          //creación
          noUiSlider.create(range, {
            start: prevRange.length ? prevRange : [min, max],
            connect: true,
            range: {
              min: min,
              max: max
            }
          })
          range.noUiSlider.on('update', function (values, handle, unencoded) {
            /*filterPrice.querySelector(
              !handle ? '[data-bs="filter.range.min"]' : '[data-bs="filter.range.max"]'
            ).textContent = formatPrice(decimals ? unencoded[handle] : Math.round(unencoded[handle]))
            */
            [priceMin, priceMax][handle].textContent = formatPrice(decimals ? unencoded[handle] : Math.round(unencoded[handle]));
          })
          range.noUiSlider.on('change', function (values, handle, unencoded) {
            filters.splice(
              0,
              filters.filter(function (filter) {
                return filter.minPrice || filter.maxPrice
              }).length ? 2 : 0,
              { minPrice: unencoded[0].toFixed(bsale.config.currency.decimals) },
              { maxPrice: unencoded[1].toFixed(bsale.config.currency.decimals) }
            )
          })
        } catch (ex) {
          console.warn(ex);
        }
      }
    }

    // ordenar productos con selector
    function sortCollecion(e) {
      location.assign(e.target.value || location.pathname)
    }

    // actualizar state al cambiar un checkbox
    function updateFilter(e) {
      try {
        const { checked, dataset, value } = e.target
        if (checked) {
          filters.push({
            //si no encuentra data-filter utiliza data-info
            [dataset.filter || dataset.info]: value
          })

        } else {
          /*
          si el filtro no esta checkeado se hace un filter a los del state para obtener su index
          y para cerciorarse de que realmente esta el filtro, luego se elimina
          */
          let filterIndex
          filters.filter((filter, i) => {
            //si no encuentra data-filter utiliza data-info

            const prevFilter = filter[dataset.filter || dataset.info] === value

            if (prevFilter) {
              filterIndex = i
              return prevFilter
            }
          }).length && filters.splice(filterIndex, 1)
        }

      } catch (ex) {
        console.warn(ex)
      }
      if (auto) {
        filterCollection(e);
      }

    }

    // crear query de filtros y cambiar la url
    function filterCollection(e) {
      try {
        let filterQuery = '';
        e.preventDefault()
        filters.forEach((filter, i) => {
          const [filterKey] = Object.keys(filter)
          filterQuery += `${filterQuery ? '&' : '?'}${filterKey}=${filters[i][filterKey]}`

        })
        location.search = filterQuery.replace(/&page=\d+/gm, "")
      } catch (ex) {
        console.warn(ex)
      }
    }

    //despliega attr seleccionados  
    function openAttr() {
      //funciona con bootstrap por eso es jquery
      let url = window.location.href;
      if (url.indexOf("?")) {
        let $attrSelector = $(".collapse")
        $attrSelector.each(function () {
          let elem = $(this);
          if (url.indexOf(elem.attr("id")) > -1) {
            elem.collapse("show")
          }
        })
      }
    }
    openAttr()

    //asignacion de evento

    sort ? sort.addEventListener('change', sortCollecion) : null;
    attrSelect.forEach(el => { el.addEventListener('change', updateFilter); el.removeAttribute("disabled") });
    form.addEventListener('submit', filterCollection)
  }
}

async function handleAddToCart(e) {
  e.preventDefault();
  const btn = e.currentTarget;
  btn.disabled = true;
  btn.classList.add("bs-loading")

  const father = this.closest('[data-bs="product"]')
  const productId = parseInt(father.dataset.info)
  const select
  const product //objeto producto 
  const id

  // recorre todas las colecciones y cuando define el producto sale
  for (let i = 0; bsale.collections.length > i; i++) {
    product = bsale.collections[i].items.filter(p => p.id === productId);
    if (product.length > 0) { break }
  }

  product = product[0]

  //Busca las variantes con getVariants(trae las variantes), si no encuentra carga product.variants(variante por defecto)
  const variants = await product.getVariants()

  // function crea selector
  function createSelect(variants) {
    // crea un selector de un array de variantes, deben tener id y description
    const select = document.createElement('select');
    select.setAttribute("data-bs", "collection.product.variant");
    select.classList.add('custom-select')

    function addOption(o) {
      const option = document.createElement('option');
      option.value = o.id || "";
      option.textContent = o.description || "";
      select.appendChild(option);
    }

    addOption({ id: '0', description: 'Seleccionar' })  

    variants.forEach(addOption);
    return select;
  }

  async function getCartRender(ctName) {
    const res = await fetch(`/cart/get_render_cart/${ctName}`);
    const cartData = await res.json();
    return cartData;
  }

  //agregar al carro
  async function thisAddToCart(variant){
    console.log('this add to cart A')
    console.log('this add to cart A')
    console.log('this add to cart A')
    let id = variant.id
    console.log('variant --> ',variant)
    await bsale.cart.add(variant, 1).then(cartItem => {

      const isRelatedProduct = btn.getAttribute('data-from-modal') == 'true' ? true : false;
      cartItem.isRelatedProduct = isRelatedProduct;
      updateCart(cartItem); //saque la condicion
      metrics.addToCart(cartItem);
      
      // Si esta en el resumen del carro 
      if (window.location.pathname === '/cart/display/carro' || window.location.pathname === '/cart/display/') {
        let ctName = '';
        try {
          // component_name: variable con nombre del componente que se obtiene del componente del detalle del carro
          ctName = component_name;
        }
        catch (ex) {
          // si no existe la variable se asigna el nombre del componente por defecto
          console.warn("Using default component name", ex);
          ctName = 'Carro > detalle';
        }

        getCartRender(ctName).then(data => {

          // Obtiene el componente del que envuelve el detalle del carro
          let element = document.querySelector('[data-bs="cart.detail"]');
          if (!element) {
            element = document.querySelector('.bs-cart > .container > .row');
          }
          // si no encuentra el elemento, se recarga la pagina
          if (!element || !data || !data.cartHTML) {
            console.error('No se encontro el elemento o no se pudo obtener el render del carro');
            location.reload();
            return;
          }

          // Reemplaza el contenido del componente del detalle del carro con lo que devuelve el render desde el market
          element.innerHTML = data.cartHTML;
          btn.disabled = false;
          btn.classList.remove("bs-loading");
                   
          cart(true);
          // si se agrego un producto en el resumen del carro se oculta la seccion del carro esta vacio 
          if (bsale.cart.items > 0) {
            const cartEmpty = document.querySelector('[data-bs="cart.empty"]');
            cartEmpty?.classList.add('d-none'); 
          }

          return;
        }).catch(err => {
          console.error("ERROR", err.message);
          location.reload();
          return;   
        });
        
      }
      // Si es un producto relacionado y se agrega al carro desde el modal
      if (isRelatedProduct) {
        btn.disabled = true;
        btn.classList.remove("bs-loading");
        let resp = btn.innerHTML;
        btn.innerHTML = 'Producto agregado &#10004;';
        setTimeout(() => {
          btn.innerHTML = resp;
          btn.disabled = false;
        }, 2000);
      } else {
        btn.disabled = false;
        btn.classList.remove("bs-loading");
      }
    })
    .catch(function (error) {
      console.log(error)
      newCreateModal("error", error.message);
      btn.disabled = false;
      btn.classList.remove("bs-loading")
    })
  }

  if (variants.length > 1) {
    select = createSelect(variants)
    const modalContent = `<div class="mb-3">${product.title}</div> 
                          <div class="mb-3">${select.outerHTML}</div>
                          <div  data-bs="containerPrice" class="mt-3 text-right d-none">
                            Precio normal:<strike data-bs="fpWithoutDiscount" class="ml-2"></strike>
                          </div>
                          <p data-bs="discountCant" class="text-right small mb-0"></p>
                          <div data-bs="price" class="text-right"></div>
                          `

    const modalFooter = `<button class="btn btn-secondary" data-bs="collection.addToCart">Agregar al carro</button>`
    newCreateModal("select", modalContent, modalFooter)
    btn.classList.remove("bs-loading")
    btn.disabled = false
    const addToCartButtonRef = document.querySelector('[data-bs="collection.addToCart"]') //addCart
    const containerPrice = document.querySelector('[data-bs="containerPrice"]')
    const fpWithoutDiscount = document.querySelector('[data-bs="fpWithoutDiscount"]')
    const discountCant = document.querySelector('[data-bs="discountCant"]')
    const price = document.querySelector('[data-bs="price"]')
    const selectVariant = document.querySelector('[data-bs="collection.product.variant"]')
    addToCartButtonRef.disabled = true
    addToCartButtonRef.setAttribute('title', 'Seleccione una opción')
    //selectVariant[0].disabled = true

    for(let i=0; i < variants.length; i++) {
      if(!variants[i].getStock() && variants[i].allowNegativeStock == 0){
        selectVariant[i + 1].disabled = true
        selectVariant[i + 1].textContent = `${selectVariant[i + 1].textContent} - Sin Stock`
      }
    }

    selectVariant.onchange = () => {
      const [variant] = variants.filter(variant => variant.id == selectVariant.options[selectVariant.selectedIndex].value)
      if(variant.discounts.length > 0){
        containerPrice.classList.remove('d-none')
        fpWithoutDiscount.classList.remove('d-none')
        
        fpWithoutDiscount.textContent = `${formatPrice(variant.salePrices.fpWithoutDiscount)}`

        if(variant.discounts[0].minimumQuantity > 1) {
          discountCant.classList.remove('d-none')
          discountCant.textContent = `Por compras desde ${variant.discounts[0].minimumQuantity} unidades`
          price.textContent = `Solo: ${formatPrice(variant.salePrices.finalPrice)}`
        }else if(variant.discounts[0].minimumQuantity === 1){
          discountCant.classList.add('d-none')
          fpWithoutDiscount.textContent = `${formatPrice(variant.salePrices.fpWithoutDiscount)}`
          price.textContent = `Solo: ${formatPrice(variant.salePrices.finalPrice)}`
        } 
      }else{
        discountCant.classList.add('d-none')
        containerPrice.classList.add('d-none')
        price.textContent = `Precio: ${formatPrice(variant.salePrices.finalPrice)}`
      }
    }
    
    selectVariant.addEventListener('change', function() { 
      id=parseInt(this.value);                    
      if(id > 0) { 
        addToCartButtonRef.disabled = false
        addToCartButtonRef.setAttribute('title', '')
      } else {
        addToCartButtonRef.disabled = true
        addToCartButtonRef.setAttribute('title', 'Seleccione una opción')
      }
    })

    addToCartButtonRef.onclick = function() { 
    let variant = product.variants.filter(v => v.id === id)[0]

      thisAddToCart(variant);
      let modalAddtoCart = this.closest('[data-bs="modal.new"]')
      modalAddtoCart.parentNode.removeChild(modalAddtoCart);
      document.body.style.paddingRight = "0";
      document.body.style.overflow = "auto";
    } 

  } 
  else if (product.variantId != variants[0].id) {
    thisAddToCart(product.variants[0]);
  }
  else {
    let variant = product.variants.filter(v => v.id === product.variantId)[0]
    thisAddToCart(variant);
  }
}

// función que inicializa el botón de agregar al carrito dentro de un componente de colección,
// puede ser en todo el documento o en un elemento del dom
export function addToCartCollection(target = null) {
  if(bsale.collections[0] != undefined){
    const container = target === null ? document : target;
    try {
      let btnAdd = container.querySelectorAll('[data-bs="cart.add.collection"]'); //addCart
      for (let i = 0; i < btnAdd.length; i++) {
        btnAdd[i].addEventListener("click", handleAddToCart)
      }
    } catch (ex) {
      console.warn(ex)
    }
  }
}

// Obtiene los productos para ser dibujados en un componente de colección ('Inicio > coleccion lazy load')
// y agrega la coleccion al DOM y al objeto global
// @param HTMLElement ==> target
async function setProductsToCollection(target) {
  let collSlug = target.getAttribute('data-slug').toString().split('/');
      collSlug = collSlug[collSlug.length - 1];

  // get all atributes target that start with 'data-param-'
  let params = {};
  let attrs = target.attributes;
  for (let i = 0; i < attrs.length; i++) {
    if (attrs[i].name.startsWith('data-param-')) {
      params[attrs[i].name.replace('data-param-', '')] = attrs[i].value;
    }
  }
  
  const data = await getProductsByCollSlug(collSlug, params);

  if (data === null) {
    setProductsToContainer(target, '');
  } else {
    setProductsToContainer(target, data.productsHTML);
    if (bsale) {
      bsale.collections.push(new Collection({
        name: params['title'],
        items: data.collection
      }));
  
      addToCartCollection();
    }
  }
}

const setInnerHTML = function(elm, html) {
  elm.innerHTML = html;
  Array.from(elm.querySelectorAll("script")).forEach( oldScript => {
    const newScript = document.createElement("script");
    Array.from(oldScript.attributes)
      .forEach( attr => newScript.setAttribute(attr.name, attr.value) );
    newScript.appendChild(document.createTextNode(oldScript.innerHTML));
    oldScript.parentNode.replaceChild(newScript, oldScript);
  });
}

// Agrega los productos a una coleccion lazy
// @param HTMLElement ==> target
// @param HTML string ==> productsHTML
export function setProductsToContainer(target, productsHTML, container_slug = '.product__content') {
  const productContainer = target.querySelector(container_slug);
  // productContainer.innerHTML = '';
  // productContainer.innerHTML = productsHTML;
  setInnerHTML(productContainer, '');
  setInnerHTML(productContainer, productsHTML);

  addToCartCollection(productContainer);

  // cargamos las imagenes de los productos en la coleccion de manera lazy
  new LazyLoad({
    elements_selector: '.lazy'
  });
  
  const slider = productContainer.querySelector('[data-bs="slider"]');

  if (slider) {
    initSlider(slider);
  }
  
}

// Obtiene los productos de una coleccion por su nombre o titulo
// @param string ==> collSlug
// @param object ==> params
async function getProductsByCollSlug(collSlug , params = {}) {
  try {
    let paramsSlug = '';
    if(Object.keys(params).length > 0) {
      paramsSlug = `?${ Object.keys(params).map(key => `${key}=${params[key]}`).join('&') }`;
    }
    const response = await fetch(`/collection/details/${ collSlug }${ paramsSlug }`);
    const data = await response.json();
    return data;
  } catch (error) {
    return null;
  }
}

// Obtiene todos los contenedores de colecciones con la clase '.lazy__load'
// y asigna observador para hacer lazy loading
export async function paintCollectionsProductsLazyLoad() {
  const containers = document.querySelectorAll('.lazy__load');
  
  async function intersection(entries, observer) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      if (entry.isIntersecting) {
        // Realizar todas las peticiones
        setProductsToCollection(entry.target);
        
        // Quitar el observado para no repetir el fetch
        observer.unobserve(entry.target);
      }
    }
  }

  const options = {
    root: null,
    rootMargin: '1000px',
    threshold: 0
  }

  const observer = new IntersectionObserver(intersection, options);

  containers.forEach(div => {
    // console.log('div', div);
    observer.observe(div);
  });
}