import bsale from '../Bsale'
import { formatPrice } from '../util/formatPrice'
import { selectorBs } from '../util/selectorBs'
import { metrics } from '../Metrics'
import { newCreateModal } from '../util'
import { createModal } from "../util/modal"

import { ConvertStringToHTML } from '../util/helper'
import { cartContent, itemDuplicated } from '../Bsale/Cart'

let cartEmptyRef
let cartFilledRef
let cartSubTotalRef
let containerItemsCart
let dropdownCart
const UPDATED_CART_ERROR = "Tu carro fue actualizado";
let isNewDropdownCart = false;

//Se obtiene información del carro
export async function getCart() {
  try {
    if (location.pathname.indexOf('cart/display') === -1) {
      await bsale.cart.get()

      const { exists } = existsDropdownCart();
      if (bsale.cart.type === 2) {
        // Si no hay contenedor del carrito, renderiza todo el contenido
        renderCartDropdown();
        cart();
      } else if (bsale.cart.type === 1 && exists) {
        // Carga Nueva
        isNewDropdownCart = true;
        renderCartItemsV2(cartContent.html);
        renderCartDropdownV2();
        cart();
      }

    }
    updateHeader()
  }
  catch (error) {
    console.log(error);
    console.warn('getCart error', error.message)
  }
}

function existsDropdownCart() {
  const dropdownCart = document.querySelector("[data-bs='cart.dropdown']");
  return {
    element: dropdownCart,
    exists: dropdownCart ? true : false
  }
}

//obtiene el tipo de descuento
function getDiscountType() {
  let discountType = localStorage.getItem("discountType")
  if (discountType) {
    const { date, value } = JSON.parse(discountType)
    if (new Date(date + (60 * 60 * 12 * 1000)) > new Date) {
      return value
    }
  }
  const discount = document.querySelector(".bs-discount")
  discountType = discount ? discount.className : "bs-discount tag"
  localStorage.setItem("discountType", JSON.stringify({
    date: Date.now(),
    value: discountType
  }))
  return discountType
}

function handleClickOutsite() {
  document.addEventListener("click", function handler(e) {
    if (!dropdownCart.contains(e.target)) {
      dropdownCart.classList.remove("showElement")
      document.removeEventListener("click", handler)
    }
  })
}

// Renderiza el dropdown del carro al cargar la pagina
function renderCartDropdownV2() {

  const anchors = document.querySelectorAll("[href^='/cart/display']")

  let cartAnchor
  if (anchors.length === 1) {
    cartAnchor = anchors[0]
  }
  else {
    for (const a of anchors) {
      if (a.offsetParent) {
        cartAnchor = a
        break
      }
    }
  }

  // Show or hide cart empty message
  cartEmpty();

  // Show or hide info cart
  infoCart();

  cartAnchor.parentNode.classList.add("btnCart")

  cartEmptyRef = document.querySelector("[data-bs='cart.empty']") //Carro cuando esta vacío
  cartFilledRef = document.querySelector("[data-bs='cart.filled']") //Carro cuando tiene items 
  cartSubTotalRef = document.querySelector("[data-bs='cart.subtotal']") //Subtotal del carro 
  containerItemsCart = document.querySelector("[data-bs='containerItemsCart']") // contiene los items del carro

  dropdownCart = document.querySelector("[data-bs='cart.dropdown']") // carro dropdown
  // dropdownCart.style.top = `${topOffset + 10}px`
  const btnCloseDropdown = document.querySelectorAll("[data-bs='btnCloseDropdown']") // botton cerrar dropdown

  dropdownCart.addEventListener('click', function (e) {
    e.stopPropagation();
  })

  for (const cartAnchor of anchors) {
    cartAnchor.addEventListener('click', e => {
      e.preventDefault()
      if (!dropdownCart.hidden) {
        e.stopPropagation()
        handleClickOutsite()
      }
      dropdownCart.classList.toggle("showElement")
    })
  }

  for (let i = 0; i < btnCloseDropdown.length; i++) {
    btnCloseDropdown[i].addEventListener('click', function () {
      //dropdownCart.classList.remove("showElement")
    })
  }
}

// Renderiza cada item dentro del carro
async function renderCartItemV2(cartItem) {
  let item = {
    detailId: cartItem.id,
    ctName: cartContent.ctName
  }

  item = JSON.stringify(item);

  const res = await fetch(`/cart/get_cart_item/${item}`);
  const cartData = await res.json();

  const cartDropdown = ConvertStringToHTML(cartData.cartHTML);
  const productHTML = cartDropdown.querySelector('[data-bs="cart.item"]');

  if (!productHTML) {
    return;
  }

  const productContainer = document.querySelector('[data-bs="containerItemsCart"]');

  if (!productContainer) {
    return;
  }

  productContainer.appendChild(productHTML);

  // Show or hide cart empty message
  cartEmpty();
  // Show or hide info cart
  infoCart();

  await bsale.cart.get();
  cart(false, cartItem.id);
}

//Renderiza cada item dentro del carro
export function renderCartItemsV2(cartHTML) {
  let cartContainer = document.querySelector('[data-bs="cart.dropdown"]');
  // CartDropdown nuevo
  let _cartDropdown = ConvertStringToHTML(cartHTML);
  _cartDropdown = _cartDropdown.querySelector('[data-bs="cart.dropdown"]');

  // Reemplazamos el contenido con los items del carrito
  if (_cartDropdown) {
    cartContainer.innerHTML = _cartDropdown.innerHTML;
  }
}

//Renderiza el dropdown del carro al cargar la pagina
function renderCartDropdown() {

  const anchors = document.querySelectorAll("[href^='/cart/display']")

  let cartAnchor
  if (anchors.length === 1) {
    cartAnchor = anchors[0]
  }
  else {
    for (const a of anchors) {
      if (a.offsetParent) {
        cartAnchor = a
        break
      }
    }
    window.addEventListener("resize", e => {
      for (const a of anchors) {
        if (a.offsetParent) {
          cartAnchor = a
          break
        }
      }
      dropdownCart.parentElement.removeChild(dropdownCart)
      cartAnchor.insertAdjacentElement("afterend", dropdownCart)
    })
  }

  //cartAnchor.parentNode.classList.add('btnCart')
  let topOffset = cartAnchor.offsetHeight

  let html = `
  <div data-bs="cart.dropdown" class="dropdownContent">
    <div data-bs="containerItemsCart" class="containerItemsCart">
  `
  html += `${cartEmpty()}`

  if (bsale.cart.items > 0) {
    for (const item of bsale.cart.detail) {
      html += `${renderCartItem(item)}`
    }
  }

  html += `${infoCart()}`

  cartAnchor.insertAdjacentHTML("afterend", html)

  cartAnchor.parentNode.classList.add("btnCart")

  cartEmptyRef = document.querySelector("[data-bs='cart.empty']") //Carro cuando esta vacío
  cartFilledRef = document.querySelector("[data-bs='cart.filled']") //Carro cuando tiene items 
  cartSubTotalRef = document.querySelector("[data-bs='cart.subtotal']") //Subtotal del carro 
  containerItemsCart = document.querySelector("[data-bs='containerItemsCart']") // contiene los items del carro

  dropdownCart = document.querySelector("[data-bs='cart.dropdown']") // carro dropdown
  // dropdownCart.style.top = `${topOffset + 10}px`
  const btnCloseDropdown = document.querySelectorAll("[data-bs='btnCloseDropdown']") // botton cerrar dropdown

  dropdownCart.addEventListener('click', function (e) {
    e.stopPropagation();
  })

  for (const cartAnchor of anchors) {
    cartAnchor.addEventListener('click', e => {
      e.preventDefault()
      if (!dropdownCart.hidden) {
        e.stopPropagation()
        handleClickOutsite()
      }
      dropdownCart.classList.toggle("showElement")
    })
  }

  for (let i = 0; i < btnCloseDropdown.length; i++) {
    btnCloseDropdown[i].addEventListener('click', function () {
      dropdownCart.classList.remove("showElement")
    })
  }

}

//Renderiza el dropdown cuando esta vacío
function cartEmpty() {
  const anchor = document.querySelector("[href^='/cart/display']")
  let iconCart = anchor.querySelector("i")
  let titleCartEmpty = 'Tú carro está vacío.';

  const { exists } = existsDropdownCart();
  const cartEmptyHTML = document.querySelector('[data-bs="cart.empty"]');
  if (exists && cartEmptyHTML) {
    if (bsale.cart.items > 0) {
      // OCULTA el mensaje de carro vacio
      cartEmptyHTML.classList.add('d-none');
    } else {
      // MUESTRA el mensaje de carro vacio
      cartEmptyHTML.classList.remove('d-none');
    }
    return;
  }

  if (iconCart) {
    iconCart = iconCart.outerHTML
    if (iconCart.indexOf('bag') > -1) {
      titleCartEmpty = 'Tú bolsa está vacía.'
    }
  } else {
    iconCart = `<i class="fas fa-shopping-cart"></i>`
  }

  let cartEmpty = `
    <div data-bs="cart.empty" class="containerCartEmpty ${bsale.cart.items > 0 ? `d-none` : ""}">
      ${iconCart}
      <p>${titleCartEmpty}</p>
      <button data-bs="btnCloseDropdown" class="btn btn-primary mx-1 w-50">Cerrar</button>
    </div>
  `
  return cartEmpty
}

//Renderiza cada item dentro del carro
function renderCartItem(item) {
  let renderCartItem = `
  <section data-bs="cart.item" data-info="${item.id}">
    <div class="containerItemCart fadeIn">
      <div class="detailItemCart">
        <div class="bs-img-square">
          <div data-bs="cart.item.discount" class="${getDiscountType()} d-none">
              <strong data-bs="cart.item.discount.value">
                -${item.variant.discount}%
              </strong>
          </div>
          <picture>
              <img src="${item.variant.image}" alt="${item.variant.name}" title="${item.variant.name}">
          </picture>
        </div>

        <div class="infoItemCart">
          <a href="${item.variant.link}" title="${item.variant.name}">
            ${item.variant.name}
          </a>
          <span class="textPriceUnity">Precio unidad</span>
          <strike data-bs="cart.item.oldPrice">
            ${formatPrice(item.variant.price)}
          </strike>
          <div data-bs="cart.item.unitPrice">
            ${formatPrice(item.variant.finalPrice)}
          </div>
          <div data-bs="cart.stock.msg" class="d-none"></div>
        </div>
      </div>

      <div class="containerButtonsCart">
        <div class="quantityButtons">
          <div class="input-group">
            <div class="input-group-prepend">
              <button data-bs="cart.quantity.minus" class="btn btn-secondary">
                <i class="fas fa-minus"></i>
              </button>
            </div>
            <input data-bs="cart.quantity" class="form-control text-center" type="number" value="${Math.round(item.quantity)}" min="1">
            <div class="input-group-append ">
              <button data-bs="cart.quantity.plus" class="btn btn-secondary">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="buttonRemoveItem">
          <button data-bs="cart.remove" data-info="" class="btn btn-secondary">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </section>`
  return renderCartItem
}

//Renderiza el subtotal y el boton ir a pagar 
function infoCart() {
  const { exists } = existsDropdownCart();
  if (exists) {
    const footerDropdownCart = document.querySelector('[data-bs="cart.filled"]');

    if (!footerDropdownCart) {
      return;
    }

    if (bsale.cart.items > 0) {
      footerDropdownCart.classList.remove('d-none');
    } else {
      footerDropdownCart.classList.add('d-none');
    }
    return;
  }

  let infoCart = `
  </div>
  <div data-bs="cart.filled" class="footerDropdownCart ${bsale.cart.items === 0 ? `d-none` : ""}"> 
    <hr>
    <div class="containerSubtotal">
      <h5>
        Subtotal: <span data-bs='cart.subtotal'> ${formatPrice(bsale.cart.total)}</span>
      </h5>
    </div>

    <div class="d-none">
      <button data-bs="cart.keepbuying" class="btn btn-primary" title="Seguir Comprando">Seguir comprando</button>
    </div>

    
    <div class="containerButtonGoToPay">
      <button data-bs="btnCloseDropdown" class="btn btn-secondary mx-1">Cerrar</button>
      <a data-bs="cart.continue" href="/cart/display/" class="btn btn-primary mx-1"> Continuar <i class="fas fa-arrow-right"></i></a>
    </div>
  </div>
  `
  return infoCart
}

//Lleva el scroll al fondo
function bottomScroll() {
  var containerItem = document.querySelector("[data-bs='containerItemsCart']");
  containerItem.scrollTop = containerItem.scrollHeight;
}

//  Extracción funciones del carro ------------------------------------
let subTotal
let totalItems
let continueToCheckoutButton
//Actualiza los elementos dentro del dropdown a medida que se van agregando.
export function updateCart(item) {
  console.log('update cart')
  console.log('update cart')

  //const { element: dropdownCart } = existsDropdownCart();

  const { exists } = existsDropdownCart();
  console.log('exist > ', exists)
  console.log('bsale cart type > ', bsale.cart)

  if (bsale.cart.type !== 2 && !exists) {
    newCreateModal("add", item);
  } else {
    dropdownCart.classList.add('showElement')

    if (bsale.cart.items > 0 && cartEmptyRef) {
      cartEmptyRef.classList.add("d-none")
    }

    let html = '';

    if (itemDuplicated.isDuplicated) {
      console.log('Es duplicado ---');
      //const dropdownCart = document.querySelector("[data-bs='cart.dropdown']");
      dropdownCart.classList.add('showElement');
    } else {
      console.log('No es duplicado ---');
      if (!isNewDropdownCart) {
        html = renderCartItem(item);
        containerItemsCart.insertAdjacentHTML("beforeend", html);
      } else {
        renderCartItemV2(item);
      }
    }

    let id = item.id
    if (cartSubTotalRef) {
      cartSubTotalRef.textContent = formatPrice(bsale.cart.total)
    }
    if (cartFilledRef) {
      cartFilledRef.classList.remove("d-none")
    }

    handleClickOutsite()

    bottomScroll()

    if (!isNewDropdownCart && !itemDuplicated.isDuplicated) {
      setCartDetailEvents(id);
    }
    dropdownCart.classList.add('showElement');
  }
  updateHeader()
}

function updateUI() {
  const newItems = `(${bsale.cart.items})`
  if (bsale.cart.items) {
    if (subTotal) {
      subTotal.textContent = formatPrice(bsale.cart.total)
    }
  }
  const { exists } = existsDropdownCart();
  if (bsale.cart.type != 2 && !exists) {
    totalItems.textContent = newItems
  }
  document.title = document.title.replace(/\([0-9]+\)/, newItems)
  updateHeader()
}

export function setCartDetailEvents(id = 0) {
  const office = document.querySelectorAll('[data-bs="office"]'); /* new */
  let cartItems = document.querySelectorAll(`[data-bs="cart.item"]${id ? `[data-info="${id}"]` : ""}`);

  if (!cartItems) {
    cartItems = [];
  }

  function updateFromCart(e) {
    const item = this.closest('[data-bs="cart.item"]')
    const id = parseInt(item.dataset.info)
    const [cartItem] = bsale.cart.detail.filter(item => item.id === id)

    const input = item.querySelector('input');
    let newQuantity = 1;
    if (!input) {
      newQuantity = cartItem.quantity;
    } else {
      newQuantity = parseInt(input.value || 1);
    }
    const prevQuantity = cartItem.quantity

    //disable buttons 
    function disable(value) {
      item.querySelectorAll('button').forEach(el => {
        if (value) {
          el.setAttribute('disabled', true)
        }
        else {
          el.removeAttribute('disabled')
        }
      })

      if (value) {
        input?.setAttribute('disabled', true);
        if (continueToCheckoutButton) {
          continueToCheckoutButton.setAttribute('disabled', true)
        }
      }
      else {

        input?.removeAttribute('disabled');
        if (continueToCheckoutButton) {
          continueToCheckoutButton.removeAttribute('disabled')
        }
      }
    }
    // update quantity
    async function update(newQuantity) {
      try {
        // lock user interactivity
        disable(true)

        let difference = newQuantity - cartItem.quantity
        if (difference < 0) {
          metrics.removeFromCart(cartItem, (difference * -1))
        } else if (difference > 0) {
          metrics.addToCart(cartItem, difference)
        }

        const updatedItem = await bsale.cart.update(cartItem, newQuantity)
        const discount = item.querySelector('[data-bs="cart.item.discount"]') //? item.querySelector('[data-bs="cart.item.discount"]'): undefined; 
        const oldPrice = item.querySelector('[data-bs="cart.item.oldPrice"]') //? item.querySelector('[data-bs="cart.item.oldPrice"]') : undefined;
        const unitPrice = item.querySelector('[data-bs="cart.item.unitPrice"]')


        //evento add / delete by quantity 


        // set unit final price

        if (unitPrice) {
          unitPrice.textContent = formatPrice(updatedItem.variant.finalPrice)
        }

        if (updatedItem.variant.discount > 0) {
          if (discount !== null) {
            discount.querySelector('[data-bs="cart.item.discount.value"]').textContent = `-${updatedItem.variant.discount}%`
            discount.classList.remove('d-none')
          }
          if (oldPrice !== null) {
            oldPrice.classList.remove('d-none')
          }
        } else {
          if (discount !== null) {
            discount.classList.add('d-none')
          }
          if (oldPrice !== null) {
            oldPrice.classList.add('d-none')
          }
        }

        input?.value = updatedItem.quantity
        // update item's total price
        if (bsale.cart.type != 2) {
          let itemFinalPrice = item.querySelector('[data-bs="cart.item.finalPrice"]')
          //valida existencia del elemento
          if (itemFinalPrice) {
            itemFinalPrice.textContent = formatPrice(updatedItem.variant.subTotal)
          }
        }
        updateUI()
      }
      catch (error) {
        input?.value = prevQuantity;
        let action = "Cerrar"
        let reload = false
        if (error.message == UPDATED_CART_ERROR) {
          action = "Recargar"
          reload = true
        }
        const [modal, close] = createModal(`
          <div class="modal-body">
            ${error.message}
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-bs="close">${action}</button>
          </div>
        `)
        modal.querySelector("[data-bs='close']").onclick = reload ? () => location.reload() : close
      }
      finally {
        // unlock user interactivity
        disable(false)
      }
    }
    // check whether input was changed or plus| minus buttons were clicked
    switch (this.dataset.bs) {
      case 'cart.quantity':
        if (newQuantity < 1) {
          input.value = 1
          update(1)
          alert(`No es posible agregar ${newQuantity} items`)
        }
        else if (newQuantity > bsale.config.addToCartLimit) {
          input.value = bsale.config.addToCartLimit
          update(bsale.config.addToCartLimit)
          alert(`Sólo es posible agregar ${bsale.config.addToCartLimit} items`)
        }
        else {
          update(newQuantity)
        }
        break
      case 'cart.quantity.plus':
        if (newQuantity < bsale.config.addToCartLimit) {
          update(newQuantity + 1)
        }
        else {
          alert(`No es posible agregar ${bsale.config.addToCartLimit} items`)
        }
        break
      case 'cart.quantity.minus':
        if (newQuantity > 1) {
          update(newQuantity - 1)
        }
    }
  }

  async function removeFromCart(e) {
    const item = this.closest('[data-bs="cart.item"]')
    const itemId = parseInt(item.dataset.info)
    const [cartItem] = bsale.cart.detail.filter(item => item.id === itemId)
    const input = item.querySelector('input')
    this.setAttribute('disabled', true)
    function disable(value) {
      item.querySelectorAll('button').forEach(el => {
        if (value) {
          el.setAttribute('disable', true)
        }
        else {
          el.removeAttribute('disable')
        }
      })
      if (value) {
        input?.setAttribute('disable', true)
      }
      else {
        input?.removeAttribute('disable')
      }
    }
    e.preventDefault()
    // lock user interactivity
    disable(true)
    try {
      const deletedItem = await bsale.cart.remove(cartItem)
      item.classList.add('fadeOut')
      setTimeout(() => {
        item.parentNode.removeChild(item)
        //---- new if office exists --------------------------------------------------------------
        if (office.length) {
          let itemInOffice = Bsale.cart.detail.filter(
            v => v.variant.variantInfo.stockInfo.some(s => s.quantityAvailable > 0 && s.office.id == office[0].options[office[0].selectedIndex].value)
              || v.variant.variantInfo.allowNegativeStock == 1 || v.variant.variantInfo.unlimitedStock == 1
          ).length

          if (office[0].options[office[0].selectedIndex].value != 0) {
            disabledOption(bsale.cart.items != itemInOffice)
          }
        }

        if (bsale.cart.items === 0) {
          const cartEmpty = document.querySelector('[data-bs="cart.empty"]');
          const cartFilled = document.querySelector('[data-bs="cart.filled"]');

          if (cartEmpty) {
            cartEmpty.classList.remove('d-none');
          }

          if (cartFilled) {
            cartFilled.classList.add('d-none');
          }
        }
      }, 500)
      updateUI()
      metrics.removeFromCart(deletedItem)
    }
    catch (error) {
      let action = "Cerrar"
      let reload = false
      if (error.message == UPDATED_CART_ERROR) {
        action = "Recargar"
        reload = true
      }
      const [modal, close] = createModal(`
        <div class="modal-body">
          ${error.message}
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs="close">${action}</button>
        </div>
      `)
      modal.querySelector("[data-bs='close']").onclick = reload ? () => location.reload() : close
      // unlock user interactivity
      disable(false)
    }
  }
  // hide or show the discount and old price, according to discount status
  for (const el of cartItems) {
    const discount = el.querySelector('[data-bs="cart.item.discount"]') //? el.querySelector('[data-bs="cart.item.discount"]'): undefined;
    const oldPrice = el.querySelector('[data-bs="cart.item.oldPrice"]') //? el.querySelector('[data-bs="cart.item.oldPrice"]'): undefined;
    const input = el.querySelector('[data-bs="cart.quantity"]')
    const [detail] = bsale.cart.detail.filter(detail => detail.id == el.dataset.info)

    if (detail.variant.discount > 0) {
      if (discount !== null) {
        discount.classList.remove('d-none')
      }
      if (oldPrice !== null) {
        oldPrice.classList.remove('d-none')
      }
    }
    else {
      if (discount !== null) {
        discount.classList.add('d-none')
      }
      if (oldPrice !== null) {
        oldPrice.classList.add('d-none')
      }
    }
    if (discount !== null) {
      discount.style.opacity = 1
    }
    //input.onchange = updateFromCart
    if (input) {
      input.addEventListener("change", updateFromCart, false);
      input.onfocus = () => {
        if (continueToCheckoutButton) {
          continueToCheckoutButton.setAttribute('disabled', true);
        }
      }
      input.onblur = () => {
        if (continueToCheckoutButton) {
          continueToCheckoutButton.removeAttribute('disabled');
        }
      }
    }

    //el.querySelectorAll('[data-bs="cart.quantity.plus"], [data-bs="cart.quantity.minus"]').forEach(el => el.onclick = updateFromCart)
    let quantityBtns = el.querySelectorAll('[data-bs="cart.quantity.plus"], [data-bs="cart.quantity.minus"]');
    if (quantityBtns) {
      for (let i = 0; i < quantityBtns.length; i++) {
        quantityBtns[i].addEventListener("click", updateFromCart, false);
      }
    }
    //el.querySelector('[data-bs="cart.remove"]').onclick = removeFromCart
    if (el.querySelector('[data-bs="cart.remove"]')) {
      el.querySelector('[data-bs="cart.remove"]').addEventListener("click", removeFromCart, false)
    }
  }
}

export function updateHeader() {
  let cartItems = document.querySelectorAll("[data-bs='cart.items']");
  let cartTotal = document.querySelectorAll("[data-bs='cart.total']");
  let total = bsale.cart.total ? bsale.cart.total : 0;

  if (!cartItems) {
    cartItems = [];
  }

  if (!cartTotal) {
    cartTotal = [];
  }

  for (let i = 0; i < cartItems.length; i++) {
    let item = cartItems[i];
    item.textContent = bsale.cart.items
  }
  for (let i = 0; i < cartTotal.length; i++) {
    let item = cartTotal[i];
    if (typeof total == 'number') {
      item.textContent = formatPrice(total)
    } else {
      item.textContent = total;
    }
  }
}

export function cart(inCart, detailId = 0) {

  const office = document.querySelectorAll('[data-bs="office"]'); /* new */
  //const continueToCheckoutButton = document.querySelectorAll('[data-bs="cart.checkout"]'); /* new */
  const cbxCheckout = document.querySelector('[data-bs="cart.checkout.check"]'); /* new */
  let cartItems = document.querySelectorAll('[data-bs="cart.item"]')
  if (!cartItems) {
    cartItems = []
  }

  subTotal = document.querySelector('[data-bs="cart.subtotal"]')
  totalItems = document.querySelector('[data-bs="cart.totalItems"]')

  const { exists } = existsDropdownCart();
  if (inCart || (bsale.cart.type === 1 && !exists)) {
    continueToCheckoutButton = document.querySelector('[data-bs="cart.checkout"]')
  } else {
    continueToCheckoutButton = document.querySelector('[data-bs="cart.continue"]')
  }

  function keepBuying() {
    location.href = "/";
  }

  // feedback when user clicks the continue to checkout button without checking the terms and conditions
  function continueToCheckout() {
    const { exists } = existsDropdownCart();
    if (bsale.cart.type !== 2 && !exists) {
      if (cbxCheckout.checked) {
        location.assign('/checkout')
      }
      else {
        cbxCheckout.classList.add('is-invalid')
        cbxCheckout.onchange = () => {
          cbxCheckout.classList.remove('is-invalid')
          cbxCheckout.onchange = null
        }
      }
    } else {
      if (location.pathname.indexOf('cart/display') === 1) {
        location.assign('/checkout');
      } else {
        location.assign('/cart/display');
      }
    }
  }

  function disabledOption(boolean) {
    if (boolean) {//TRUE desactiva botones y check
      if (cbxCheckout !== null) { cbxCheckout.setAttribute("disabled", true); }
      for (let i = 0; i < continueToCheckoutButton.length; i++) {
        continueToCheckoutButton[i].setAttribute("disabled", true);
        continueToCheckoutButton[i].setAttribute("title", "Hay productos que no están en la sucursal seleccionada");
      }
    } else {//FALSE activa botones y check
      if (cbxCheckout !== null) { cbxCheckout.removeAttribute("disabled"); }
      for (let i = 0; i < continueToCheckoutButton.length; i++) {
        continueToCheckoutButton[i].removeAttribute("disabled");
        continueToCheckoutButton[i].setAttribute("title", "Continuar");
      }
    }
  }//disableOption

  function offices() {
    if (office.length) {
      const msg = office[0].dataset.info ? office[0].dataset.info : '<div class="alert alert-secondary mt-0 mb-0" role="alert">Sin Stock en {sucursal}</div>';
      let optionSelected; //opcion seleccionada del select
      let productInOffice = []; // lista de productos en la sucursal
      //revisa stock de productos en sucursal
      function checkStock() {
        optionSelected = this.options[this.selectedIndex];
        productInOffice = Bsale.cart.detail.filter(
          v => v.variant.variantInfo.stockInfo.some(s => s.quantityAvailable > 0 && s.office.id == optionSelected.value)
            || v.variant.variantInfo.allowNegativeStock == 1 || v.variant.variantInfo.unlimitedStock == 1
        );

        disabledOption(productInOffice.length < cartItems.length);
        // por cada item cart 
        for (let i = 0; i < cartItems.length; i++) {
          let child = cartItems[i].querySelector("[data-bs='cart.stock.msg']");
          if (child === null) {//si el child no existe lo crea donde reemplaza {sucursal} por nombre de la sucursal
            cartItems[i].insertAdjacentHTML("beforeend", `<div data-bs='cart.stock.msg' style='opacity:0;display:none'>${msg.replace("{sucursal}", optionSelected.text)}</div>`);
            child = item.querySelector("[data-bs='cart.stock.msg']");
          } else {//si existe lo oculta
            child.innerHTML = msg.replace("{sucursal}", optionSelected.text); //reemplaza {sucursal} por nombre de la sucursal
            child.className = '';
            child.style.opacity = "0";
            child.style.display = "none"
          }

          // si el cartItem ESTA en la Sucursal 
          if (productInOffice.filter(v => v.id == cartItems[i].dataset.info).length) {
            //imagen se muestra normal
            cartItems[i].style.filter = "grayscale(0%)";
            //quita esta clase al item
            cartItems[i].classList.remove("bs-cart-noneStock");
            //oculta child
            child.style.opacity = "0";
            child.style.display = "none"
          } else {// si NO ESTA
            // imagen en GRIS
            cartItems[i].style.filter = "grayscale(100%)";
            // agregar clase bs-cart-noneStock
            cartItems[i].classList.add("bs-cart-noneStock");
            //oculta hijo
            child.style.opacity = "1";
            child.style.display = "block"
          }
        }//for cartItem  
      }//check:end

      /* -------------------
      Actions
      --------------------*/
      if (cbxCheckout !== null) {
        cbxCheckout.setAttribute("disabled", true);
      }
      for (let i = 0; i < office.length; i++) {
        office[i].addEventListener("change", checkStock);
      }
      /*for(let i = 0; i < btnDelete.length; i++){
        btnDelete[i].addEventListener("click", delItemOffice );
      }*/
    }

  }//office:end

  function quote() {
    //función que llena el formulario de cotización con una tabla de los productos seleccionados
    const productInput = document.getElementById('cotizar-productos')
    var cartDetailquote = `<tr>
                        <th style='border: 1px solid;padding:5px 10px'></th>
                        <th style='border: 1px solid;padding:5px 10px'>Nombre</th>
                        <th style='border: 1px solid'>SKU</th>
                        <th style='border: 1px solid;padding:5px 10px'>Cantidad</th>
                      </tr>`;
    for (let i = 0; Bsale.cart.detail.length > i; i++) {
      let item = bsale.cart.detail[i].variant
      cartDetailquote += `<tr>
                          <td style='border: 1px solid; padding:5px 10px'>${i + 1}</td>
                          <td style='border: 1px solid;padding:5px 10px'>${item.name}</td>
                          <td style='border: 1px solid;padding:5px 10px'>${item.sku}</td>
                          <td style='border: 1px solid;padding:5px 10px;text-align:right'>${item.cd_q}</td>
                        </tr>`;
    }
    console.log(cartDetailquote)
    if (productInput !== null) {
      //oculta el textarea
      productInput.parentNode.firstChild.style.display = "none";
      productInput.style.display = "none"
      //llena el formulario
      productInput.value = `<table style='border-collapse: separate;'>${cartDetailquote}</table>`
    } else {
      console.log('falta el campo "cotizar productos" en el formulario')
    }
  }
  //asignnacion evento cotizar al boton
  const quoteButton = document.querySelectorAll('[data-bs="quote"]');
  for (let i = 0; i < quoteButton.length; i++) {
    quoteButton[i].addEventListener('click', quote)
  }


  offices();
  updateUI()

  let btnkeepbuying = document.querySelector('[data-bs="cart.keepbuying"]')
  if (btnkeepbuying) {
    btnkeepbuying.onclick = keepBuying
  }
  if (continueToCheckoutButton) {
    continueToCheckoutButton.addEventListener("click", continueToCheckout)
  }

  if (detailId !== 0) {
    setCartDetailEvents(detailId);
  } else {
    setCartDetailEvents();
  }
}

